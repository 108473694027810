import {
  Metric,
  onLCP, onFID, onCLS, onFCP, onTTFB,
} from 'web-vitals';
import { initPerfume } from 'perfume.js';

import { METRICS_ENDPOINT } from 'config/constants/metrics';

import fetch from 'common/utils/fetch';

interface IMetrics extends Omit<Metric, 'name'> {
  name: Metric['name'] | 'TBT'
}

const sendMetrics = (body: string, state: IAppState) => {
  if (navigator.sendBeacon) {
    navigator.sendBeacon(METRICS_ENDPOINT, body);
  } else {
    fetch(METRICS_ENDPOINT, 'POST', state, undefined, body);
  }
};

export const webVitalsMetrics = (state: IAppState) => {
  const sendQueue = new Set<IMetrics>();

  const addToSendQueue: (
    metric: IMetrics
  ) => void = metric => {
    sendQueue.add(metric);
  };

  const flushSendQueue = () => {
    if (sendQueue.size <= 0) return;

    sendMetrics(JSON.stringify({
      pageName:    state.runtime.currentPage,
      pageSection: state.runtime.currentSection,
      metrics:     [...sendQueue],
    }), state);

    sendQueue.clear();
  };

  onLCP(addToSendQueue);
  onFID(addToSendQueue);
  onCLS(addToSendQueue);
  onFCP(addToSendQueue);
  onTTFB(addToSendQueue);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  initPerfume({
    analyticsTracker: (options: any) => {
      const {
        attribution,
        metricName,
        data,
        rating,
        navigationType,
      } = options;
      const newRating = rating === 'needsImprovement' ? 'needs-improvement' : rating;

      switch (metricName) {
        case 'TBT':
          addToSendQueue({
            name:           'TBT',
            value:          data as number,
            rating:         newRating!,
            delta:          data as number,
            id:             '',
            // @ts-ignore
            entries:        attribution?.eventEntry ? [attribution.eventEntry] : [],
            navigationType: navigationType!,
          });
          break;
        default:
          break;
      }
    },
  });

  document.addEventListener('visibilitychange', () => {
    if (document.visibilityState === 'hidden') {
      flushSendQueue();
    }
  });

  // Safari хак (вместо visibilitychange)
  document.addEventListener('pagehide', flushSendQueue);
};
