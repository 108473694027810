import 'polyfills';
import React from 'react';
import { createRoot, hydrateRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { loadableReady } from '@loadable/component';

import * as SentryReact from '@sentry/react';
import 'utils/sleep-refresh';

import { YANDEX_METRICS } from 'config/constants/counters';
import { PUB_SCOPE } from 'config/constants/ad';

import YandexEvent from 'utils/counters/YandexEvent';
import { initSentry } from 'utils/sentry/client';

import App from 'desktop/App';
import configureStore from 'common/redux/configure';

import { webVitalsMetrics } from 'utils/metrics/webVitals';
import { initServiceWorker } from 'service-worker/initServiceWorker';

declare const window: EnhancedWindow;

const container = document.getElementById('root');

// eslint-disable-next-line no-underscore-dangle
const store = configureStore(window.__PRELOADED_STATE__);

// Пропатчим начальный стейт, если адблок
// Антиадблок не сразу детектит блокировщики рекламы, поэтому на клиенте на всякий
// случай проверяем сами
if (!window.QW50aS1BZEJsb2Nr) {
  // eslint-disable-next-line no-underscore-dangle
  window.__PRELOADED_STATE__.runtime.antiadblockEnabled = false;
}

// eslint-disable-next-line no-underscore-dangle
window._config_aab = {
  publisherFirstParty: {
    // eslint-disable-next-line no-underscore-dangle
    uid:   window.__PRELOADED_STATE__.runtime.ruid,
    scope: PUB_SCOPE,
  },
};

window.ramblerIdHelper = window.ramblerIdHelper || [];
window.ramblerIdHelper.push(() => {
  window.ramblerIdHelper.addListener('register', () => {
    (new YandexEvent(YANDEX_METRICS.COMMON)).send({
      type:   'reachGoal',
      data:   'ID_registration',
      params: {
        auth_type: 'ID_authorized',
      },
    });
  });
});

const state = store.getState();

webVitalsMetrics(state);
initServiceWorker(state.runtime);
initSentry(state.runtime);

loadableReady().then(() => {
  const component = (
    <BrowserRouter>
      <Provider store={store}>
        <SentryReact.ErrorBoundary>
          <App />
        </SentryReact.ErrorBoundary>
      </Provider>
    </BrowserRouter>
  );

  if (module.hot) {
    const root = createRoot(container!);
    root.render(component);
  } else {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const root = hydrateRoot(container!, component);
  }
});

if (module.hot) {
  module.hot.accept();
}
